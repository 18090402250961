import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"


export default function Gallery({ data }) {
  data.allFile.edges.map(({node}) => console.log(typeof node.modifiedTime))
 data.allFile.edges.sort((a,b) => a.modifiedTime > b.modifiedTime ? 1 : -1)
 data.allFile.edges.map(( { node }) => console.log(node.modifiedTime))
 
 return (
     <Layout>
      <h3>Photo Gallery</h3>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.allFile.edges.map(({ node }) => (
          <div key={node.id} style={{ padding: 10 }}>
            <Img fixed={node.childImageSharp.fixed} />
            <p style={{ fontSize: 15 }}>{node.name}</p>
            <p style={{ fontSize: 12 }}>{node.modifiedTime}</p>
          </div>
        ))}
      </div>  
    </Layout>
  )
}




export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          id
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
          modifiedTime
          name
        }
      }
    }
  }
`

